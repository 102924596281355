import React from 'react';
import Footer from '../components/Footer/Footer'
import Cooperate from '../components/Footer/Cooperate';
import SpreadingsHead from '../components/Spreadings/SpreadingsHead/SpreadingsHead';
import SpreadingsList from '../components/Spreadings/SpreadingsList/SpreadingsList';
import OtherBrands from '../components/OtherBrands/OtherBrands';
import { Helmet } from 'react-helmet';


function Spreadings() {
   const url = window.location.href
   const brands = [
      { image: '../img/brands/INNOCENT-SNACKS.png', alt: 'Невинные закуски', title: 'Невинные закуски', link: "/snacks", target: '' },
      { image: '../img/brands/COCONESSA.png', alt: 'Coconessa', title: 'Coconessa', link: "/brands", target: '' },
      { image: '../img/brands/casa-kubana.png', alt: 'Casa Kubana', title: 'Casa Kubana', link: "https://casakubana.ru/", target: '_blank' },
   ]
   return (

      <div className="main_content green container">
         <Helmet>
            <title>Народные намазки</title>
            <meta name="title" content="Народные намазки" />
            <meta property="og:title" content="Народные намазки" />
            <meta property="twitter:title" content="Народные намазки" />

            <meta name="description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="og:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="twitter:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />

            <meta property="og:url" content={url} />
            <meta property="twitter:url" content={url} />
         </Helmet>
         <SpreadingsHead />
         <SpreadingsList />
         <OtherBrands brands={brands} />
         <Cooperate />
         <Footer />
      </div>

   );
}

export default Spreadings;
