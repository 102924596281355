import React from 'react';
import Footer from '../components/Footer/Footer'
import Cooperate from '../components/Footer/Cooperate';
import SnacksHead from '../components/Snacks/SnacksHead/SnacksHead';
import SnackList from '../components/Snacks/SnackList/SnackList';
import OtherBrands from '../components/OtherBrands/OtherBrands';
import { Helmet } from 'react-helmet';

function Snacks() {
   const url = window.location.href
   const brands = [
      { image: '../img/brands/FOLK-NAMAZKI.png', alt: 'Народные намазки', title: 'Народные намазки', link: "/spreadings", target: '' },
      { image: '../img/brands/COCONESSA.png', alt: 'Coconessa', title: 'Coconessa', link: "/brands", target: '' },
      { image: '../img/brands/casa-kubana.png', alt: 'Casa Kubana', title: 'Casa Kubana', link: "https://casakubana.ru/", target: '_blank' },
   ]
   return (

      <div className="main_content black container">
         <Helmet>
            <title>Невинные закуски</title>
            <meta name="title" content="Невинные закуски" />
            <meta property="og:title" content="Невинные закуски" />
            <meta property="twitter:title" content="Невинные закуски" />

            <meta name="description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="og:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />
            <meta property="twitter:description"
               content="Кубанский производитель растительных продуктов питания. С 2016 года радуем вкусными и качественными продуктами" />

            <meta property="og:url" content={url} />
            <meta property="twitter:url" content={url} />
         </Helmet>
         <SnacksHead />
         <SnackList />
         <OtherBrands brands={brands} />
         <Cooperate />
         <Footer />
      </div>

   );
}

export default Snacks;
